import Navbar from "../Auth/Navbar";

const SignupDoor = () => {
  return (
    <>
      <Navbar />
      <div className="signup-door">
        <h2>Choose Your Option</h2>
        <div className="signup-door-options">
          <a href="provider-signup" className="signup-door-option">Provider</a>
          <a href="patient-signup" className="signup-door-option">Patient</a>
        </div>
      </div>
    </>

  );
};

export default SignupDoor;