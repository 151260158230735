import React, { useState } from 'react';
import logo from '../../Assets/Navbar/quickcare-logo.svg';
import userIcon from '../../Assets/Navbar/user-icon.svg';

const Navbar = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);


    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <header className="nav-bg">
            <nav className="navbar">
                <div className="left">
                    <img src={logo} alt="QuickCare Logo" className="logo-img" />
                    <div className="nav-links">
                        <ul>
                            <li className="home-dropdown">
                                <a
                                    href="# "
                                    className="home-toggle-dropdown"
                                    onClick={toggleDropdown}
                                > For Providers <i class='bx bx-chevron-down'></i></a>
                                {isDropdownOpen && (
                                    <ul className="home-dropdown-menu">
                                        <li><a href="/products">Sexual Health</a></li>
                                        <li><a href="/products">Cosmetics</a></li>
                                        <li><a href="/products">IV Therapy</a></li>
                                    </ul>
                                )}
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="right">
                    <a href="/login"> <img src={userIcon} alt="User Logo" /></a>
                    <a href="/signup" className="nav-item signup-link">Sign Up</a>
                </div>
                <button className="menu-button">☰</button>
            </nav>
        </header>
    )
};

export default Navbar;