import React, { useState } from 'react';
import logo from '../../Assets/Navbar/quickcare-logo.svg';
import userIcon from '../../Assets/Navbar/user-icon.svg';
import SearchIcon from '../../Assets/SearchBar/search-icon.svg';
import SearchBarEmoji from '../../Assets/SearchBar/search-bar-emoji.svg';
import SearchBarUpperIcon from '../../Assets/SearchBar/search-bar-upper-icon.svg';
import sexualHealthImg from '../../Assets/sexual_health.jpg';
import ivTherapyImg from '../../Assets/iv_therapy2.jpg';
import aiDiagnosticsImg from '../../Assets/ai_diag.jpg';
import bookConsultationImg from '../../Assets/book.jpg';
import finalizeServicesImg from '../../Assets/finalise.jpg';
import cosmeticsImg from '../../Assets/cosmetics.jpg';
import appointmentImg from '../../Assets/Providers/appointment.jpg';
import medicalDeviceImg from '../../Assets/Providers/medical-device.jpg';
import healthcareAppImg from '../../Assets/Providers/health-care-app.jpg';
import awsLogo from '../../Assets/aws.jpg';
import shopifyLogo from '../../Assets/shopify.jpg';
import linkedinLogo from '../../Assets/Footer/linkedin-logo.svg';
import instagramLogo from '../../Assets/Footer/instagram-logo.svg';
import tiktokLogo from '../../Assets/Footer/tiktok-logo.svg';
import footerLogo from '../../Assets/Footer/quickcare-logo.svg';
import LiveChat from './liveChat.jsx';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css'; // Import Splide styles



const Home = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for mobile menu
  const [isOpen, setIsOpen] = useState(false); // State for live chat

  const date = new Date();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleMenu = (e) => {
    e.stopPropagation(); // Prevent event from bubbling up
    setIsMenuOpen(!isMenuOpen); // Toggle mobile menu
  };

  const toggleChat = (e) => {
    e.stopPropagation(); // Prevent event from bubbling up
    setIsOpen(!isOpen); // Toggle live chat
  };

  const sliderData = [
    {
      image: sexualHealthImg,
      title: 'Sexual Health',
      description:
        'Support patients in taking control of their sexual health with discreet at-home sample collection kits designed for both women and men.',
    },
    {
      image: cosmeticsImg,
      title: 'Cosmetics',
      description:
        'Enhance patient experience and accessibility to cosmetic procedures in a setting that ensures discretion and flexible scheduling.',
    },
    {
      image: ivTherapyImg,
      title: 'IV Therapy',
      description:
        'Empower patients to access the benefits of personalized IV therapy in the comfort of their own homes.',
    }
  ];

  return (
    <div className="" onClick={() => { setIsMenuOpen(false); setIsOpen(false); }}>
      <header className="nav-bg">
        <nav className="navbar">
          <div className="left">
            <img src={logo} alt="QuickCare Logo" className="logo-img" />
            <div className="nav-links">
              <ul>
                <li className="home-dropdown">
                  <a
                    href="# "
                    className="home-toggle-dropdown"
                    onClick={toggleDropdown}
                  > For Providers <i class='bx bx-chevron-down'></i></a>
                  {isDropdownOpen && (
                    <ul className="home-dropdown-menu">
                      <li><a href="# ">Sexual Health</a></li>
                      <li><a href="# ">Cosmetics</a></li>
                      <li><a href="# ">IV Therapy</a></li>
                    </ul>
                  )}
                </li>
              </ul>
            </div>
          </div>
          <div className="right">
            <a href="/login" > <img src={userIcon} alt="User Logo" /></a>
            <a href="/signup" className="nav-item signup-link">Sign Up</a>
          </div>
          {!isMenuOpen ? (
            <button className="menu-button" onClick={toggleMenu}>☰</button>
          ) : (
            <button className="close-button" onClick={toggleMenu}>✖</button>
          )}
        </nav>
        <a href="/login" className='nav-login'> <img src={userIcon} alt="User-Logo" /></a>
      </header>

      {isMenuOpen && (
        <div className="mobile-menu" onClick={(e) => e.stopPropagation()}>
          <ul>
            <li><a href="# ">Sexual Health</a></li>
            <li><a href="# ">Cosmetics</a></li>
            <li><a href="# ">IV Therapy</a></li>
          </ul>
        </div>
      )}

      <main>
        {/* <section className="hero">
          <h1>Delivering Personalized Care to Patient's Door</h1>
          <h3>AI-Driven Diagnostics Tailored to Patient Needs</h3>
          <div className="home-search-container">
            <img src={SearchBarUpperIcon} alt="Upper Icon" className="search-icon-upper" />
            <div className="search-bar">
              <img src={SearchIcon} alt="Left Icon" className="search-icon-left" />
              <input
                type="text"
                placeholder="For Patients: AI Diagnosis & Easy Provider Booking!"
                disabled
              />
              <img src={SearchBarEmoji} alt="Right Icon" className="search-icon-right" />
            </div>
          </div>

        </section>*/}
        <section className="hero"
          onClick={(e) => {
            e.stopPropagation(); // Stop propagation to prevent other handlers from interfering
            setIsOpen(true);
          }}
          style={{ cursor: "pointer" }}>

          <h1>Delivering Personalized Care to Patient's Door</h1>
          <h3>AI-Driven Diagnostics Tailored to Patient Needs</h3>
          <div className="home-search-container">
            <img
              src={SearchBarUpperIcon}
              alt="Upper Icon"
              className="search-icon-upper"
              style={{ pointerEvents: "none" }}
            />
            <div className="search-bar">
              <img
                src={SearchIcon}
                alt="Left Icon"
                className="search-icon-left"
                style={{ pointerEvents: "none" }}
              />
              <input
                type="text"
                placeholder="For Patients: AI Diagnosis & Easy Provider Booking!"
                disabled
                style={{ pointerEvents: "none" }}
              />
              <img
                src={SearchBarEmoji}
                alt="Right Icon"
                className="search-icon-right"
                style={{ pointerEvents: "none" }}
              />
            </div>
          </div>
        </section>

        <div className="how-it-works">
          <h2>How It Works: Your Personalized Care Journey</h2>
          <div className="steps">
            <article className="step">
              <img src={aiDiagnosticsImg} alt="AI Diagnostics Process" />
              <h3>Step 1: AI Diagnostics</h3>
              <p>
                <li>AI Chatbot analyzes health history with quick questions.</li>
                <li>Generates personalized insights for providers.</li>
              </p>
            </article>
            <article className="step">
              <img src={bookConsultationImg} alt="Consultation Booking Process" />
              <h3>Step 2: Book a Consultation</h3>
              <p>
                <li>Easy scheduling with providers through the platform.</li>
                <li>Connect virtually or in-person from home.</li>
              </p>
            </article>
            <article className="step">
              <img src={finalizeServicesImg} alt="Finalize Services Process" />
              <h3>Step 3: Finalize Services</h3>
              <p>
                <li>Fast access to care and follow-ups.</li>
              </p>
            </article>
          </div>
        </div>

        <div className="services">
          <h1>Providers Can Work With Us to Deliver Care</h1>
          <p>Enhance your practice with our innovative tools for improved diagnostics and treatment outcomes.</p>
          <div className="mobile-service-cards">
          <Splide
            options={{
              type: 'loop',
              perPage: 1,
              autoplay: true,
              interval: 3000,
              arrows: true,
              pagination: true,
              speed: 600,
            }}
          >
            {sliderData.map((item, index) => (
              <SplideSlide key={index}>
                <div className="slider-card">
                  <img
                    src={item.image}
                    alt={item.title}
                    className="slider-card-image"
                  />
                  <div className="slider-card-content">
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                  </div>
                </div>
              </SplideSlide>
            ))}
          </Splide>
          </div>
          <div className="service-cards">
            <div className="service-card">
              <div className="service-card-content">
                <img src={sexualHealthImg} alt="Sexual Health Services" />
                <div className="service-overlay">
                  <h3>Sexual Health</h3>
                  <p>Support patients in taking control of their sexual health with discreet at-home sample collection kits designed for both women and men.</p>
                  <button className="read-more">Read More</button>
                </div>
              </div>
            </div>
            <div className="service-card">
              <div className="service-card-content">
                <img src={cosmeticsImg} alt="Cosmetics Services" />
                <div className="service-overlay">
                  <h3>Cosmetics</h3>
                  <p>Enhance patient experience and accessibility to cosmetic procedures in a setting that ensures discretion and flexible scheduling.</p>
                  <button className="read-more">Read More</button>
                </div>
              </div>
            </div>
            <div className="service-card">
              <div className="service-card-content">
                <img src={ivTherapyImg} alt="IV Therapy" />
                <div className="service-overlay">
                  <h3>IV Therapy</h3>
                  <p>Empower patients to access the benefits of personalized IV therapy in the comfort of their own homes.</p>
                  <button className="read-more">Read More</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="providers">
          <div className="provider-features">
            <div className="feature-card">
              <div className="image-wrapper">
                <img src={appointmentImg} alt="Appointment Scheduling Tool" />
              </div>
              <p>Providers can easily manage appointments using our HIPAA compliant scheduling tool, ensuring seamless booking and timely patient care.</p>
            </div>
            <div className="feature-card">
              <div className="image-wrapper">
                <img src={medicalDeviceImg} alt="AI-driven Insights" />
              </div>
              <p>Harness AI-powered insights and recommendations to deliver personalized care and tailored treatment plans, improving patient outcomes.</p>
            </div>
            <div className="feature-card">
              <div className="image-wrapper">
                <img src={healthcareAppImg} alt="Subscription Platform" />
              </div>
              <p>Provide consistent care with our subscription platform.</p>
            </div>
          </div>
        </div>

        <div className="trusted-by-section">
          <p>Trusted by</p>
          <div className="trusted-logos-container">
            <div className="trusted-logos">
              <img src={awsLogo} alt="AWS" />
              <img src={shopifyLogo} alt="Shopify" />
            </div>
          </div>
        </div>
      </main>
      <LiveChat isOpen={isOpen} setIsOpen={setIsOpen} />

      <footer className="footer">
        <div className="footer-logo">
          <img src={footerLogo} alt="QuickCare Logo" />
        </div>
        <div className="footer-bottom">
          <div className="footer-links">
            <a href="/about-us">About Us</a>
            <a href="/contact-us">Contact Us</a>
            <a href="/terms-and-conditions">Terms & Conditions</a>
            <a href="/careers" className="careers-link">Careers</a>
          </div>
          <div className="footer-socials">
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
              <img src={linkedinLogo} alt="LinkedIn" />
            </a>
            <a href="https://www.tiktok.com" target="_blank" rel="noopener noreferrer">
              <img src={tiktokLogo} alt="TikTok" />
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <img src={instagramLogo} alt="Instagram" />
            </a>
          </div>
        </div>
      </footer>
      <div className='copyright-text'>
        <p>Copyright © {date.getFullYear()} Get Quick Care, All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default Home;