import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchPateintsDetails } from "../../ApiHandler/PatientFunctions";
import { handleSendInvitation, fetchBookedAppointments } from "../../ApiHandler/BookingFunctions";

const Booking = () => {
    const [patients, setPatients] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [appointments, setAppointments] = useState([]);
    const [formData, setFormData] = useState({
        providerType: "",
        timeSlot: "",
        meetLink: "",
    });

    useEffect(() => {
        fetchPateintsDetails(setPatients);
        fetchBookedAppointments(setAppointments);
    }, []);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredPatients = patients.filter((patient) =>
        patient.patient_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        patient.patient_email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleBookClick = (patient) => {
        setSelectedPatient(patient);
        setFormData((prevFormData) => ({
            ...prevFormData,
            patientId: patient.patient_id,
        }));
        setShowModal(true);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <div className="add-patient-container booking-container">
            <ToastContainer />
            <div className="left-section">
                <div className="search-container">
                    <input
                        type="text"
                        placeholder="Search Patients"
                        value={searchTerm}
                        onChange={handleSearch}
                        className="search-input"
                    />
                </div>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>SNO</th>
                                <th>Patient Name</th>
                                <th>Patient Email</th>
                                <th>Patient Phone</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredPatients.map((patient, index) => (
                                <tr key={patient.id}>
                                    <td>{index + 1}</td>
                                    <td>{patient.patient_name}</td>
                                    <td>{patient.patient_email}</td>
                                    <td>{patient.patient_phone}</td>
                                    <td>
                                        <button
                                            className="update-button"
                                            onClick={() => handleBookClick(patient)}
                                        >
                                            Book
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="right-section">
                <h3>Booked Appointments</h3>
                <div className="appointments-container">
                    {appointments.length > 0 ? (
                        appointments.map((appointment) => (
                            <div key={appointment.session_id} className="appointment-card">
                                <i class="bx bx-dots-vertical-rounded icon-dots"></i>
                                <p><strong>Patient:</strong> {appointment.patient_name}</p>
                                <p><strong>Email:</strong> {appointment.patient_email}</p>
                                {/* <p><strong>Phone:</strong> {appointment.patient_phone}</p> */}
                                {/* <p><strong>Provider Type:</strong> {appointment.provider_type}</p> */}
                                <p><strong>Time Slot:</strong> {appointment.time_slot}</p>
                                <p><strong>Meet Link:</strong> <a href={appointment.meet_link} target="_blank" rel="noopener noreferrer">Join</a></p>
                            </div>
                        ))
                    ) : (
                        <p>No appointments found.</p>
                    )}
                </div>
            </div>

            {showModal && selectedPatient && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h2>Book Appointment</h2>
                        <p><strong>Patient Name:</strong> {selectedPatient.patient_name}</p>
                        <p><strong>Patient Email:</strong> {selectedPatient.patient_email}</p>
                        <p><strong>Patient Phone:</strong> {selectedPatient.patient_phone}</p>
                        <div className="form-group">
                            <label>Provider Type</label>
                            <input
                                type="text"
                                name="providerType"
                                value={formData.providerType}
                                onChange={handleInputChange}
                                placeholder="Enter provider type (e.g., Cosmetics)"
                            />
                        </div>
                        <div className="form-group">
                            <label>Time Slot</label>
                            <input
                                type="text"
                                name="timeSlot"
                                value={formData.timeSlot}
                                onChange={handleInputChange}
                                placeholder="Enter time slot (e.g., 10:00 AM - 11:00 AM)"
                            />
                        </div>
                        <div className="form-group">
                            <label>Meet Link</label>
                            <input
                                type="text"
                                name="meetLink"
                                value={formData.meetLink}
                                onChange={handleInputChange}
                                placeholder="Enter meeting link (e.g., Zoom/Google Meet)"
                            />
                        </div>
                        <div className="modal-actions">
                            <button onClick={(e) => handleSendInvitation(e, formData, setFormData, setShowModal, setLoading)} disabled={loading} className="save-button">
                                {loading ? 'Sending Invitation...' : 'Send Invitation'}
                            </button>
                            <button onClick={() => setShowModal(false)} className="cancel-button">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Booking;
