import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchLocationInput from '../Utils/SearchLocationInput.js';
import { fetchPateintsDetails, handleUpdatePatient } from "../../ApiHandler/PatientFunctions";
import { fetchKits } from '../../ApiHandler/ShopifyFunctions';

const UpdatePatients = () => {
  const [patients, setPatients] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [popupPatientIndex, setPopupPatientIndex] = useState(null);
  const [editingPatientId, setEditingPatientId] = useState(null);
  const [editDetails, setEditDetails] = useState({});
  const [kits, setKits] = useState([]);

  useEffect(() => {
    fetchKits(setKits);
  }, []);

  useEffect(() => {
    fetchPateintsDetails(setPatients);
  }, []);

  // const getKitNameById = (kitId) => {
  //   const kit = kits.find(k => k.id === Number(kitId));
  //   return kit ? `kit ${kits.indexOf(kit) + 1}` : 'Unknown Kit';
  // };

  // Handle search input change
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredPatients = patients.filter(patient =>
    patient.patient_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    patient.patient_email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Start editing a patient
  const startEditing = (patient) => {
    setEditingPatientId(patient.patient_id);
    setEditDetails(patient);
  };

  // Handle input changes in the edit mode
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditDetails((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSelectedLocation = (formattedAddress, city, state, country, postalCode) => {
    setEditDetails((prev) => ({
      ...prev,
      patient_street: formattedAddress,
      patient_city: city || prev.patient_city,
      patient_state: state || prev.patient_state,
      patient_country: country || prev.patient_country,
      patient_postal_code: postalCode || prev.patient_postal_code,
    }));
  };

  const closePopup = () => {
    setPopupPatientIndex(null);
  };

  return (
    <>
      <ToastContainer />
      <div className="search-container">
        <input
          type="text"
          placeholder="Search Patients"
          value={searchTerm}
          onChange={handleSearch}
          className="search-input"
        />
      </div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>SNO</th>
              <th>Patient Name</th>
              {/* <th>Patient Email</th> */}
              <th>Patient Phone</th>
              <th>Patient Age</th>
              <th>Patient Address</th>
              {/* <th>Shipping Type</th>
              <th>Frequency</th>
              <th>Kit Type</th> */}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredPatients.map((patient, index) => (
              <tr key={patient.id}>
                <td>{index + 1}</td>
                {editingPatientId === patient.patient_id ? (
                  <>
                    <td>
                      <input
                        type="text"
                        name="patient_name"
                        value={editDetails.patient_name}
                        onChange={handleEditChange}
                        required
                      />
                    </td>
                    {/* <td>
                      <input
                        type="email"
                        name="patient_email"
                        value={editDetails.patient_email}
                        onChange={handleEditChange}
                        required
                      />
                    </td> */}
                    <td>
                      <input
                        type="tel"
                        name="patient_phone"
                        value={editDetails.patient_phone}
                        onChange={handleEditChange}
                        required
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="patient_age"
                        value={editDetails.patient_age}
                        onChange={handleEditChange}
                        required
                      />
                    </td>
                    <td className='address-container'>
                      <input
                        type="text"
                        name="patient_street"
                        placeholder="Enter Address"
                        value={editDetails.patient_street}
                        onClick={() => setPopupPatientIndex(index)}
                        readOnly
                      />
                    </td>
                    {/* <td>
                      <select
                        name="shipping_type"
                        value={editDetails.shipping_type}
                        onChange={handleEditChange}
                        required
                      >
                        <option value="">Select</option>
                        <option value="USPS">USPS</option>
                        <option value="Overnight">Overnight</option>
                      </select>
                    </td>
                    <td>
                      <select
                        name="frequency"
                        value={editDetails.frequency}
                        onChange={handleEditChange}
                        required
                      >
                        <option value="">Select</option>
                        <option value="3 Months">3 Months</option>
                        <option value="1 Time">1 Time</option>
                        <option value="1 Month">1 Month</option>
                        <option value="Yearly">Yearly</option>
                      </select>
                    </td>
                    <td>
                      <select
                        name="kit_type"
                        value={editDetails.kit_type}
                        onChange={handleEditChange}
                        required
                      >
                        <option value="">Select</option>
                        {kits.map((kit, index) => (
                          <option value={kit.id}>
                            kit {index + 1}
                          </option>
                        ))}
                      </select>
                    </td> */}
                    <td>
                      <button
                        className="update-button"
                        onClick={() => handleUpdatePatient(patient.patient_id, editDetails, setPatients, setEditingPatientId)}
                      >
                        Save
                      </button>
                    </td>
                  </>
                ) : (
                  <>
                    <td>{patient.patient_name}</td>
                    {/* <td>{patient.patient_email}</td> */}
                    <td>{patient.patient_phone}</td>
                    <td>{patient.patient_age}</td>
                    <td>{patient.patient_street}</td>
                    {/* <td>{patient.shipping_type}</td>
                    <td>{patient.frequency}</td>
                    <td>{getKitNameById(patient.kit_type)}</td> */}
                    <td>
                      <button
                        className="update-button"
                        onClick={() => startEditing(patient)}
                      >
                        Edit
                      </button>
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {popupPatientIndex !== null && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Patient Address</h2>
              <span className="close-btn" onClick={closePopup}>&times;</span>
            </div>
            <div className="modal-body">
              <div style={{ width: '100%', marginBottom: '10px' }}>
                <SearchLocationInput
                  setSelectedLocation={handleSelectedLocation}
                  initialQuery={editDetails.patient_street}
                  style={{ width: '100%', boxSizing: 'border-box' }} // Ensure the input takes the full width
                />
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <input
                  type="text"
                  name="patient_city"
                  placeholder="City"
                  value={editDetails.patient_city}
                  onChange={handleEditChange}
                  style={{
                    flex: '1',
                    marginRight: '10px',
                    boxSizing: 'border-box',
                  }}
                />
                <input
                  type="text"
                  name="patient_postal_code"
                  placeholder="Postal Code"
                  value={editDetails.patient_postal_code}
                  onChange={handleEditChange}
                  style={{
                    flex: '1',
                    boxSizing: 'border-box',
                  }}
                />
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <input
                  type="text"
                  name="patient_state"
                  placeholder="State"
                  value={editDetails.patient_state}
                  onChange={handleEditChange}
                  style={{
                    flex: '1',
                    marginRight: '10px',
                    boxSizing: 'border-box',
                  }}
                />
                <input
                  type="text"
                  name="patient_country"
                  placeholder="Country"
                  value={editDetails.patient_country}
                  onChange={handleEditChange}
                  style={{
                    flex: '1',
                    boxSizing: 'border-box',
                  }}
                />
              </div>
              <div style={{ width: '100%', marginTop: '10px' }}>
                <input
                  type="text"
                  name="address_line_2"
                  placeholder="Address Line 2 (Optional)"
                  value={editDetails.address_line_2}
                  onChange={handleEditChange}
                  style={{ width: '100%', boxSizing: 'border-box' }}
                />
              </div>
              <button
                type="submit"
                className="save-button"
                onClick={closePopup}
                style={{marginTop: '10px', padding: '8px 20px 8px 20px'}}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdatePatients;