import React from 'react';
import ReactDOM from "react-dom";
import Home from '../src/Components/Home/Home';
import Products from './Components/Home/Products';
import AboutUs from './Components/Home/AboutUs';
import ContactUs from './Components/Home/ContactUs';
import TermsCondition from './Components/Home/TermsConditions';
import Careers from './Components/Home/Careers';
import SignupDoor from './Components/Home/SignupDoor';
import PaymentSuccess from './Components/Payment/PaymentSuccess';
import PaymentCancel from './Components/Payment/PaymentCancel';
import Login from '../src/Components/Auth/Login';
import ForgotPassword from './Components/Auth/ForgotPassword';
import ResetPassword from './Components/Auth/ResetPassword';
import ClinicSignUp from '../src/Components/Auth/ClinicSignUp';
import PatientSignUp from '../src/Components/Auth/PatientSignUp';
import AddressConfirmation from '../src/Components/Auth/AddressConfirmation';
import Dashboard from '../src/Components/Dashboard/Dashboard';
import './index.css';

import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />
    },
    {
        path: "/products",
        element: <Products />
    },
    {
        path: "/about-us",
        element: <AboutUs />
    },
    {
        path: "/contact-us",
        element: <ContactUs />
    },
    {
        path: "/terms-and-conditions",
        element: <TermsCondition />
    },
    {
        path: "/careers",
        element: <Careers />
    },
    {
        path: "/signup",
        element: <SignupDoor />
    },
    {
        path: "/payment-success",
        element: <PaymentSuccess />
    },
    {
        path: "/payment-cancel",
        element: <PaymentCancel />
    },
    {
        path: "/patient-signup",
        element: <PatientSignUp />
    },
    {
        path: "/login",
        element: <Login />
    },
    {
        path: "/forgot-password",
        element: <ForgotPassword />
    },
    {
        path: "/reset-password/:token",
        element: <ResetPassword />
    },
    {
        path: "/confirm-address/:token",
        element: <AddressConfirmation />
    },
    {
        path: "/provider-signup",
        element: <ClinicSignUp />
    },
    {
        path: "/dashboard",
        element: <Dashboard />
    },
]);

ReactDOM.render(
    <RouterProvider router={router} />,
    document.getElementById("root")
);