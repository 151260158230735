import { useState, useRef } from 'react';
import Chatbot_assistant from '../../Assets/Chatbot_img.jpg';
const CHATBOT_API_URL = process.env.REACT_APP_CHATBOT_API_URL;

const LiveChat = ({ isOpen, setIsOpen }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [size, setSize] = useState({ width: 320, height: 480 }); // Initial size
  const chatWindowRef = useRef(null);

  const topics = ['Sexual Health', 'Cosmetics', 'IV Therapy'];

  const sendMessage = async (msg) => {
    try {
      const response = await fetch(`${CHATBOT_API_URL}/api/chat`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ message: msg })
      });
      const data = await response.json();
      setMessages([...messages,
      { type: 'user', text: msg },
      { type: 'bot', text: data.response }
      ]);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    const startX = e.clientX;
    const startY = e.clientY;
    const startWidth = chatWindowRef.current.offsetWidth;
    const startHeight = chatWindowRef.current.offsetHeight;

    const handleMouseMove = (e) => {
      const newWidth = Math.max(200, startWidth + (e.clientX - startX)); // Minimum width
      const newHeight = Math.max(300, startHeight + (e.clientY - startY)); // Minimum height
      setSize({ width: newWidth, height: newHeight });
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  return (
    <div className="livechat-container">
      {isOpen ? (
        <div className="chat-window" ref={chatWindowRef} style={{ width: size.width, height: size.height }}>
          <div className="chat-header">
            <div className="agent-info">
            <img src={Chatbot_assistant} alt="Chatbot_1" style={{ width: '30px', height: '30px' }} /> 
               
              <div>
                <div className="agent-name">Chatbot </div>
                <div className="agent-title">Support Agent</div>
              </div>
            </div>
            <button className="close-button" onClick={() => setIsOpen(false)}>✕</button>
          </div>

          <div className="chat-image">
          </div>
          
          <div className="chat-messages">
            {messages.length === 0 && (
              <>
                <div className="message bot">
                  <div className="message-bubble">Hi, I'm here to help</div>
                </div>
                <div className="message bot">
                  <div className="message-bubble">
                    Welcome to LiveChat. Pick a topic from the options below or type down a question!
                  </div>
                </div>
                <div>
                  {topics.map(topic => (
                    <button
                      key={topic}
                      onClick={() => sendMessage(topic)}
                      className="topic-button"
                    >
                      {topic}
                    </button>
                  ))}
                </div>
              </>
            )}
            {messages.map((msg, idx) => (
              <div key={idx} className={`message ${msg.type}`}>
                <div className="message-bubble">{msg.text}</div>
              </div>
            ))}
          </div>

          <div className="chat-input">
            <form className="input-form" onSubmit={(e) => {
              e.preventDefault();
              if (input.trim()) {
                sendMessage(input);
                setInput('');
              }
            }}>
              <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Type a message..."
                className="message-input"
              />
              <button type="submit" className="send-button">→</button>
            </form>
          </div>
          <div className="resizer" onMouseDown={handleMouseDown} /> {/* Resizer element */}
        </div>
      ) : null}
    </div>
  );
};

export default LiveChat;