import React, { useState, useEffect } from 'react';
import { IoCloseCircleOutline } from 'react-icons/io5';
import logo from '../../Assets/logo.png';
// import authenticate from '../../Assets/dashboard/Authenticate.svg';
import patients from '../../Assets/dashboard/Patients.svg';
import order from '../../Assets/dashboard/Order.svg';
import support from '../../Assets/dashboard/Support.svg';

function Sidebar({ isSidebarOpen, toggleSidebar, role, handleClick }) {
    const [activeOption, setActiveOption] = useState(localStorage.getItem('activeOption') || 'patientProfile');

    useEffect(() => {
        localStorage.setItem('activeOption', activeOption);
    }, [activeOption]);

    const handleOptionClick = (option) => {
        if (option === 'supportInfo') {
            window.open('https://docs.google.com/forms/d/e/1FAIpQLSdSCtXvMCYgdhjY1l1UCopswS1vxHVMfGa5vd8OSmLd9c7MIA/viewform', '_blank');
        } else {
            setActiveOption(option);
            handleClick(option);
            toggleSidebar();
        }
    };

    return (
        <aside className={`sidebar ${isSidebarOpen ? 'sidebar-responsive' : ''}`}>
            <div className='sidebar-title'>
                <div className='sidebar-brand'>
                    <img className='navbar-brand' src={logo} alt='QuickCare Logo' />
                    <h4>GetQuickCare</h4>
                </div>
                <span className='icon close-icon' onClick={toggleSidebar}>
                    <IoCloseCircleOutline />
                </span>
            </div>
            <ul className='sidebar-list'>
                {role === '1' && (
                    <>
                        <li
                            onClick={() => handleOptionClick('patients')}
                            className={`sidebar-list-item ${activeOption === 'patients' ? 'active' : ''}`}
                        >
                            <img src={patients} alt='Patients Icon' />
                            <label>Patients</label>
                        </li>
                        <li
                            onClick={() => handleOptionClick('authenticate')}
                            className={`sidebar-list-item ${activeOption === 'authenticate' ? 'active' : ''}`}
                        >
                            <img src={order} alt='Order Icon' />
                            <label>Booking / Order Kits</label>
                        </li>
                        {/* <li
                            onClick={() => handleOptionClick('booking')}
                            className={`sidebar-list-item ${activeOption === 'booking' ? 'active' : ''}`}
                        >
                            <i class='bx bx-calendar bx-sm'></i>
                            <label>Booking</label>
                        </li> */}
                        {/* <li
                            onClick={() => handleOptionClick('reporting')}
                            className={`sidebar-list-item ${activeOption === 'reporting' ? 'active' : ''}`}
                        >
                            <img src={reporting} alt='Reporting Icon' />
                            <label>Reporting</label>
                        </li> */}
                    </>
                )}
                {role === '0' && (
                    <>
                        <li
                            onClick={() => handleOptionClick('patientProfile')}
                            className={`sidebar-list-item ${activeOption === 'patientProfile' ? 'active' : ''}`}
                        >
                            <img src={patients} alt='Patients Icon' />
                            <label>Profile & Kit Setup</label>
                        </li>
                    </>
                )}
                <li
                    onClick={() => handleOptionClick('supportInfo')}
                    className={`sidebar-list-item`}
                >
                    <img src={support} alt='Patients Icon' />
                    <label>Support</label>
                </li>
            </ul>
        </aside>
    );
}

export default Sidebar;