import logo from '../../Assets/Navbar/quickcare-logo.svg'

const Navbar = () => {
    return (
        <>
            <header className="nav-bg">
                <nav className="navbar">
                    <div className="left">
                        <img src={logo} alt="QuickCare Logo" className="logo-img" />
                    </div>
                    <div className="right">
                        <a href="/" className="nav-item signup-link">Home</a>
                    </div>
                </nav>
            </header>
        </>
    )
}

export default Navbar