import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchPateintsDetails, updatePatientsAuthorization } from '../../ApiHandler/PatientFunctions';
import { handleSendInvitation } from "../../ApiHandler/BookingFunctions";
import { checkPaymentDetails } from '../../ApiHandler/PaymentFunctions';
import { fetchKits } from '../../ApiHandler/ShopifyFunctions';
import { fetchLabs } from '../../ApiHandler/ClinicFunctions';
// import { handleOrderKits } from '../../ApiHandler/AlertFunctions';
import { handleOrderSingleKit } from '../../ApiHandler/AlertFunctions.js';
import ConfirmationModal from '../Utils/ConfirmationModal';
import SearchLocationInput from '../Utils/SearchLocationInput.js';
import TermsModal from './Terms&Conditions.jsx';

const Authenticate = () => {
    const [patients, setPatients] = useState([]);
    const [selectedPatient, setSelectedPatient] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectAll, setSelectAll] = useState(false);
    const [hasPaymentDetails, setHasPaymentDetails] = useState(false);
    const [hasLabDetails, setHasLabDetails] = useState(false);
    const [loading, setLoading] = useState(false);
    const [kits, setKits] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showTermsModal, setShowTermsModal] = useState(false);
    // const [modalAction, setModalAction] = useState(null);
    const [orderPopup, setOrderPopup] = useState(null);
    const [formData, setFormData] = useState({
        providerType: "",
        appointmentDate: "",
        timeSlotStart: "",
        timeSlotEnd: "",
        location: "",
        meetLink: "",
    });
    const [orderKitInfo, setOrderKitInfo] = useState({
        frequency: '',
        kitType: '',
    });

    // Check if clinic has saved payment details on component load
    useEffect(() => {
        checkPaymentDetails(setHasPaymentDetails);
        fetchPateintsDetails(setPatients);
        fetchKits(setKits);
    }, []);

    useEffect(() => {
        fetchLabs((labData) => {
            const requiredFields = [
                'name',
                'labId',
                'phoneNo',
                'street',
                'city',
                'postalCode',
                'state',
                'country',
                'instructions',
            ];

            // Check if all required fields are filled
            const isComplete = requiredFields.every((field) => labData[field] && labData[field].trim() !== '');
            setHasLabDetails(isComplete);
        });
    }, []);

    // const getKitNameById = (kitId) => {
    //     const kit = kits.find((k) => k.id === Number(kitId));
    //     return kit ? `kit ${kits.indexOf(kit) + 1}` : 'Unknown Kit';
    // };

    // Handle search input change
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    // Filter patients based on search term
    const filteredPatients = patients.filter((patient) =>
        patient.patient_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        patient.patient_email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Toggle checkbox for all patients
    const handleSelectAll = () => {
        const newStatus = !selectAll;
        setSelectAll(newStatus);
        setPatients((prevPatients) =>
            prevPatients.map((patient) => ({ ...patient, isChecked: newStatus }))
        );
    };

    // Handle individual checkbox
    const handleCheckboxChange = (patientId) => {
        setPatients((prevPatients) =>
            prevPatients.map((patient) =>
                patient.patient_id === patientId
                    ? { ...patient, isChecked: !patient.isChecked }
                    : patient
            )
        );
    };

    // const handlePlaceOrder = () => {
    //     setModalAction(() => () => handleOrderKits(patients, setLoading));
    //     setIsModalOpen(true);
    // };

    const handleConfirmAction = () => {
        setIsModalOpen(false);
        if (modalAction) modalAction();
    };

    // Authenticate selected patients
    const handleAuthenticate = async () => {
        const selectedPatients = patients.filter((patient) => patient.isChecked);
        const selectedIds = selectedPatients.map((patient) => patient.patient_id);

        try {
            await updatePatientsAuthorization(selectedIds, true, setPatients, setLoading); // true for authenticate
            // After successful authentication, set authorized status to 1
            setPatients((prevPatients) =>
                prevPatients.map((patient) =>
                    selectedIds.includes(patient.patient_id)
                        ? { ...patient, authorized: 1 }
                        : patient
                )
            );
        } catch (error) {
            console.error('Error updating patients:', error);
        }
    };

    const handleBookClick = (patient) => {
        setSelectedPatient(patient);
        setFormData((prevFormData) => ({
            ...prevFormData,
            patientId: patient.patient_id,
        }));
        setShowModal(true);
    };

    const handleOrderKit = (patient) => {
        setSelectedPatient(patient);
        setOrderKitInfo((prevFormData) => ({
            ...prevFormData,
            patientId: patient.patient_id,
        }));
        setOrderPopup(true);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSelectedLocation = (formattedAddress, city, state, country, postalCode) => {
        const locationString = `${formattedAddress || ''}, ${city || ''}, ${state || ''}, ${country || ''}, ${postalCode || ''}`.replace(/,\s*,|,\s*$/, ''); // Clean up extra commas
        setFormData((prev) => ({
            ...prev,
            location: locationString,
        }));
    };

    return (
        <React.Fragment>
            <ToastContainer />
            <ConfirmationModal
                isOpen={isModalOpen}
                title="Confirm Action"
                message="Are you sure you want to place the order?"
                onConfirm={handleConfirmAction}
                onCancel={() => setIsModalOpen(false)}
            />
            <div className="patients-container">
                <div className="add-patient-container">
                    <div className='in-row-input'>
                        <div className="search-container">
                            <input
                                type="text"
                                placeholder="Search Patients"
                                value={searchTerm}
                                onChange={handleSearch}
                                className="search-input"
                            />
                        </div>
                        {(!hasPaymentDetails || !hasLabDetails) && (
                            <div className="warning-top" style={{ color: '#FFA500', textAlign: 'center', marginTop: '5px' }}>
                                Please add your card details and lab details before placing orders
                            </div>
                        )}
                    </div>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <input
                                            type="checkbox"
                                            checked={selectAll}
                                            onChange={handleSelectAll}
                                        />
                                    </th>
                                    <th>Patient Name</th>
                                    <th>Patient Email</th>
                                    <th>Patient Phone</th>
                                    <th>Patient Address</th>
                                    {/* <th>Shipping Type</th>
                                    <th>Frequency</th>
                                    <th>Kit Type</th> */}
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredPatients.map((patient) => (
                                    <tr key={patient.patient_id}>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={patient.isChecked || false}
                                                onChange={() => handleCheckboxChange(patient.patient_id)}
                                            />
                                        </td>
                                        <td>  <span
                                            className="status-dot"
                                            style={{
                                                backgroundColor: patient.authorized ? 'green' : 'red',
                                                height: '10px',
                                                width: '10px',
                                                borderRadius: '50%',
                                                display: 'inline-block',
                                                marginRight: '10px',
                                            }}
                                        ></span> {patient.patient_name}</td>
                                        <td>{patient.patient_email}</td>
                                        <td>{patient.patient_phone}</td>
                                        <td>{patient.patient_street}</td>
                                        {/* <td>{patient.shipping_type}</td>
                                        <td>{patient.frequency}</td>
                                        <td>{getKitNameById(patient.kit_type)}</td> */}
                                        <td>
                                            <button
                                                style={{ background: 'none', border: 'none', marginRight: '20px' }}
                                                onClick={() => handleBookClick(patient)}
                                            >
                                                <i class='bx bx-calendar bx-sm'></i>
                                            </button>
                                            <button style={{ background: 'none', border: 'none' }}
                                                onClick={() => handleOrderKit(patient)}
                                            >
                                                <i class='bx bx-cart-alt bx-sm'></i>
                                            </button>

                                            {/* <span
                                                className="status-dot"
                                                style={{
                                                    backgroundColor: patient.authorized ? 'green' : 'red',
                                                    height: '10px',
                                                    width: '10px',
                                                    borderRadius: '50%',
                                                    display: 'inline-block',
                                                    marginRight: '10px',
                                                }}
                                            ></span>
                                            {patient.authorized === 1 ? (
                                                <span className="not-authorized">Authorized</span>
                                            ) : (
                                                <span className="not-authorized">Not Authorized</span>
                                            )} */}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="in-row-input">
                        <button className="save-button" onClick={handleAuthenticate} disabled={loading}>
                            {loading ? 'Authenticating...' : 'Authenticate'}
                        </button>
                        {/* <button
                            style={{ backgroundColor: 'green' }}
                            className="save-button"
                            onClick={handlePlaceOrder}
                            disabled={!hasPaymentDetails || !hasLabDetails || loading}
                        >
                            {loading ? 'Placing Order...' : 'Place Order Kits'}
                        </button> */}
                    </div>
                </div>
            </div>
            {showModal && selectedPatient && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h2>Book Appointment</h2>
                        <p><strong>Patient Name:</strong> {selectedPatient.patient_name}</p>
                        <p><strong>Patient Email:</strong> {selectedPatient.patient_email}</p>
                        <p><strong>Patient Phone:</strong> {selectedPatient.patient_phone}</p>
                        <div className="form-group">
                            <label>Provider Type</label>
                            <input
                                type="text"
                                name="providerType"
                                value={formData.providerType}
                                onChange={handleInputChange}
                                placeholder="Enter provider type (e.g., Cosmetics)"
                            />
                        </div>
                        <div className="form-group">
                            <label>Date</label>
                            <input
                                type="date"
                                name="appointmentDate"
                                value={formData.appointmentDate}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Time Slot</label>
                            <input
                                type="time"
                                name="timeSlotStart"
                                value={formData.timeSlotStart}
                                onChange={handleInputChange}
                                placeholder="Start time"
                            />
                            <span>to</span>
                            <input
                                type="time"
                                name="timeSlotEnd"
                                value={formData.timeSlotEnd}
                                onChange={handleInputChange}
                                placeholder="End time"
                            />
                        </div>
                        <div className="form-group">
                            <label>Location (Optional)</label>
                            <SearchLocationInput
                                setSelectedLocation={handleSelectedLocation}
                                initialQuery={formData.location}
                                style={{ width: '100%', boxSizing: 'border-box' }} // Ensure the input takes the full width
                            />
                            {/* <input
                                type="text"
                                name="meetLink"
                                value={formData.location}
                                onChange={handleInputChange}
                                placeholder="Enter location"
                            /> */}
                        </div>
                        <div className="form-group">
                            <label>Meet Link</label>
                            <input
                                type="text"
                                name="meetLink"
                                value={formData.meetLink}
                                onChange={handleInputChange}
                                placeholder="Enter meeting link (e.g., Zoom/Google Meet)"
                            />
                        </div>
                        <div className="modal-actions">
                            <button onClick={() => setShowModal(false)} className="cancel-button">
                                Cancel
                            </button>
                            <button onClick={(e) => handleSendInvitation(e, formData, setFormData, setShowModal, setLoading)} disabled={loading} className="save-button">
                                {loading ? 'Sending Invitation...' : 'Send Invitation'}
                            </button>
                        </div>
                    </div>
                </div>
            )}


            {orderPopup && selectedPatient && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h2>Order Kit</h2>
                        <p><strong>Patient Name:</strong> {selectedPatient.patient_name}</p>
                        <p><strong>Patient Email:</strong> {selectedPatient.patient_email}</p>
                        <p><strong>Patient Age:</strong> {selectedPatient.patient_age}</p>
                        <td>
                            <label>Frequency</label>
                            <select
                                name="frequency"
                                value={orderKitInfo.frequency}
                                onChange={(e) =>
                                    setOrderKitInfo((prev) => ({
                                        ...prev,
                                        frequency: e.target.value,
                                    }))
                                }
                                required
                            >
                                <option value="">Select</option>
                                <option value="3 Months">Every 3 Months</option>
                                <option value="1 Month">Every 1 Month</option>
                                <option value="Yearly">Every Year</option>
                                <option value="1 Time">1 Time</option>
                            </select>
                        </td>
                        <td>
                            <label>Kit Type</label>
                            <select
                                name="kitType"
                                value={orderKitInfo.kitType}
                                onChange={(e) =>
                                    setOrderKitInfo((prev) => ({
                                        ...prev,
                                        kitType: e.target.value,
                                    }))
                                }
                                required
                            >
                                <option value="">Select</option>
                                {kits.map((kit, index) => (
                                    <option value={kit.id} key={kit.id}>
                                        {kit.name}
                                    </option>
                                ))}
                            </select>
                        </td>
                        <div className="modal-actions">
                            <button onClick={() => setOrderPopup(false)} className="cancel-button">
                                Cancel
                            </button>
                            <button
                                onClick={() => setShowTermsModal(true)}
                                disabled={loading}
                                className="save-button">
                                {loading ? 'Placing Order...' : 'Place Order'}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <TermsModal show={showTermsModal} accept={(e) => handleOrderSingleKit(e, orderKitInfo, setOrderKitInfo, setShowTermsModal, setOrderPopup, setLoading, hasPaymentDetails, hasLabDetails)} onClose={() => { setShowTermsModal(false) }} loading={loading} />
        </React.Fragment>
    );
};

export default Authenticate;