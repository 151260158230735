import linkedinLogo from '../../Assets/Footer/linkedin-logo.svg';
import instagramLogo from '../../Assets/Footer/instagram-logo.svg';
import tiktokLogo from '../../Assets/Footer/tiktok-logo.svg';
import footerLogo from '../../Assets/Footer/quickcare-logo.svg';

function Footer() {
  const date = new Date();
  return (
    <>
      <footer className="footer">
        <div className="footer-logo">
          <img src={footerLogo} alt="QuickCare Logo" />
        </div>
        <div className="footer-bottom">
          <div className="footer-links">
            <a href="/about-us">About Us</a>
            <a href="/contact-us">Contact Us</a>
            <a href="/terms-and-conditions">Terms & Conditions</a>
            <a href="/careers">Careers</a>
          </div>
          <div className="footer-socials">
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
              <img src={linkedinLogo} alt="LinkedIn" />
            </a>
            <a href="https://www.tiktok.com" target="_blank" rel="noopener noreferrer">
              <img src={tiktokLogo} alt="TikTok" />
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <img src={instagramLogo} alt="Instagram" />
            </a>
          </div>
        </div>
      </footer>
      <div className='copyright-text'>
        <p>Copyright © {date.getFullYear()} Get Quick Care, All Rights Reserved.</p>
      </div>
    </>
  );
}

export default Footer;