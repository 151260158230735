import React from "react";
import Navbar from '../Auth/Navbar';

function CareersNew() {
    return (
        <React.Fragment>
            <Navbar />
            <h1 style={{textAlign: 'center'}}>Careers</h1>
        </React.Fragment>
    );
}

export default CareersNew;