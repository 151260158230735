import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SearchLocationInput from '../Utils/SearchLocationInput.js';
import { handleAddPatients } from '../../ApiHandler/PatientFunctions';
import { importPatientsFromExcel } from '../Utils/ImportPatientsExcel';
import { fetchKits } from '../../ApiHandler/ShopifyFunctions';

const AddPatients = () => {
  const [patients, setPatients] = useState(() => {
    const savedPatients = localStorage.getItem('patients');
    return savedPatients
      ? JSON.parse(savedPatients)
      : Array.from({ length: 10 }, () => ({
        name: '',
        email: '',
        phone: '',
        age: '',
        street: '',
        city: '',
        postalCode: '',
        state: '',
        country: '',
        addressLine2: '',
        // shippingType: '',
        // frequency: '',
        // kitType: '',
        isChecked: false,
      }));
  });
  const [loading, setLoading] = useState(false);
  const [popupPatientIndex, setPopupPatientIndex] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const fileInputRef = React.useRef(null);
  const [kits, setKits] = useState([]);
  const tableRef = useRef(null);

  useEffect(() => {
    fetchKits(setKits);
  }, []);

  useEffect(() => {
    localStorage.setItem('patients', JSON.stringify(patients));
  }, [patients]);

  const handleInputChange = (index, field, value) => {
    const newPatients = [...patients];
    newPatients[index] = { ...newPatients[index], [field]: value };
    setPatients(newPatients);
  };

  const handleSelectedLocation = (index, formattedAddress, city, state, country, postalCode) => {
    const newPatients = [...patients];
    newPatients[index] = {
      ...newPatients[index],
      street: formattedAddress,
      city: city || newPatients[index].city,
      state: state || newPatients[index].state,
      country: country || newPatients[index].country,
      postalCode: postalCode || newPatients[index].postalCode,
    };
    setPatients(newPatients);
  };

  const handleAddRow = () => {
    setPatients([
      ...patients,
      {
        name: '',
        email: '',
        phone: '',
        age: '',
        street: '',
        city: '',
        postalCode: '',
        state: '',
        country: '',
        addressLine2: '',
        // shippingType: '',
        // frequency: '',
        // kitType: '',
        isChecked: false,
      },
    ]);
    setTimeout(() => {
      tableRef.current.scrollTop = tableRef.current.scrollHeight;
    }, 100);
  };

  const handleRemoveRow = (index) => {
    setPatients(patients.filter((_, i) => i !== index));
  };

  const closePopup = () => {
    setPopupPatientIndex(null);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      importPatientsFromExcel(file, setPatients, kits);
      setIsPopupOpen(false); // Close the popup after file upload
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="table-container" ref={tableRef}>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          style={{ display: 'none' }}
          accept=".xlsx, .xls"
        />
        <button style={{ marginBottom: '10px' }} onClick={() => setIsPopupOpen(true)} className="save-button">
          Import Patients from File
        </button>

        {/* Popup for Import Options */}
        {isPopupOpen && (
          <div className="modal-overlay">
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <div className="modal-header">
                <h2>Import Patients</h2>
                <span className="close-btn" onClick={() => setIsPopupOpen(false)}>&times;</span>
              </div>
              <p>
                Download the <a href="https://docs.google.com/spreadsheets/d/1bCPIbQgvlyJuNvelbuYeLUY8N3Qemp5s/edit?usp=sharing&ouid=105651091009971797879&rtpof=true&sd=true" target="_blank" rel="noopener noreferrer">Excel File Format</a>.
              </p>
              <button className="upload-button" onClick={() => fileInputRef.current.click()}>
                Upload File
              </button>
            </div>
          </div>
        )}

        <table>
          <thead>
            <tr>
              <th>Patient Name</th>
              <th>Patient Email</th>
              <th>Patient Phone</th>
              <th>Patient Age</th>
              <th>Patient Address</th>
              {/* <th>Shipping Type</th>
              <th>Frequency</th>
              <th>Kit Type</th> */}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {patients.map((patient, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td>
                    <input
                      type="text"
                      placeholder="Enter Name"
                      value={patient.name}
                      onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                      required
                    />
                  </td>
                  <td>
                    <input
                      type="email"
                      placeholder="Enter Email"
                      value={patient.email}
                      onChange={(e) => handleInputChange(index, 'email', e.target.value)}
                      required
                    />
                  </td>
                  <td>
                    <input
                      type="tel"
                      placeholder="Enter Phone No"
                      value={patient.phone}
                      onChange={(e) => handleInputChange(index, 'phone', e.target.value)}
                      required
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder="Enter Patient age"
                      value={patient.age}
                      onChange={(e) => handleInputChange(index, 'age', e.target.value)}
                      required
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="Enter Address"
                      value={patient.street}
                      onClick={() => setPopupPatientIndex(index)}
                      readOnly
                    />
                  </td>
                  {/* <td>
                    <select
                      value={patient.shippingType}
                      onChange={(e) => handleInputChange(index, 'shippingType', e.target.value)}
                      required
                    >
                      <option value="">Select</option>
                      <option value="USPS">USPS</option>
                      <option value="Overnight">Overnight</option>
                    </select>
                  </td>
                  <td>
                    <select
                      value={patient.frequency}
                      onChange={(e) => handleInputChange(index, 'frequency', e.target.value)}
                      required
                    >
                      <option value="">Select</option>
                      <option value="3 Months">3 Months</option>
                      <option value="1 Time">1 Time</option>
                      <option value="1 Month">1 Month</option>
                      <option value="Yearly">Yearly</option>
                    </select>
                  </td>
                  <td>
                    <select
                      value={patient.kitType}
                      onChange={(e) => handleInputChange(index, 'kitType', e.target.value)}
                      required
                    >
                      <option value="">Select</option>
                      {kits.map((kit, index) => (
                        <option value={kit.id} key={kit.id}>
                          Kit {index + 1}
                        </option>
                      ))}
                    </select>
                  </td> */}
                  <td>
                    <button className="remove-button" onClick={() => handleRemoveRow(index)}>
                      <i className="bx bx-trash"></i>
                    </button>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <div className="buttons-container">
        <button
          type="submit"
          className="save-button"
          onClick={(e) => handleAddPatients(e, patients, setPatients, setLoading)}
          disabled={loading}
        >
          {loading ? 'Saving...' : 'Save All'}
        </button>
        <button className="add-button" onClick={handleAddRow}>
          <i className="bx bx-plus"></i>
        </button>
      </div>
      {popupPatientIndex !== null && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Patient Address</h2>
              <span className="close-btn" onClick={closePopup}>&times;</span>
            </div>
            <div className="modal-body">
              <div style={{ width: '100%', marginBottom: '10px' }}>
                <SearchLocationInput
                  setSelectedLocation={(formattedAddress, city, state, country, postalCode) =>
                    handleSelectedLocation(
                      popupPatientIndex,
                      formattedAddress,
                      city,
                      state,
                      country,
                      postalCode
                    )
                  }
                  initialQuery={patients[popupPatientIndex].street}
                  style={{ width: '100%', boxSizing: 'border-box' }}
                />
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <input
                  type="text"
                  placeholder="City"
                  value={patients[popupPatientIndex].city}
                  onChange={(e) => handleInputChange(popupPatientIndex, 'city', e.target.value)}
                  style={{
                    flex: '1',
                    marginRight: '10px',
                    boxSizing: 'border-box',
                  }}
                />
                <input
                  type="text"
                  placeholder="Postal Code"
                  value={patients[popupPatientIndex].postalCode}
                  onChange={(e) =>
                    handleInputChange(popupPatientIndex, 'postalCode', e.target.value)
                  }
                  style={{
                    flex: '1',
                    boxSizing: 'border-box', // Prevents overflow issues
                  }}
                />
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <input
                  type="text"
                  placeholder="State"
                  value={patients[popupPatientIndex].state}
                  onChange={(e) => handleInputChange(popupPatientIndex, 'state', e.target.value)}
                  style={{
                    flex: '1',
                    marginRight: '10px',
                    boxSizing: 'border-box',
                  }}
                />
                <input
                  type="text"
                  placeholder="Country"
                  value={patients[popupPatientIndex].country}
                  onChange={(e) =>
                    handleInputChange(popupPatientIndex, 'country', e.target.value)
                  }
                  style={{
                    flex: '1',
                    boxSizing: 'border-box',
                  }}
                />
              </div>
              <div style={{ width: '100%', marginTop: '10px' }}>
                <input
                  type="text"
                  placeholder="Address Line 2 (Optional)"
                  value={patients[popupPatientIndex].addressLine2}
                  onChange={(e) =>
                    handleInputChange(popupPatientIndex, 'addressLine2', e.target.value)
                  }
                  style={{ width: '100%', boxSizing: 'border-box' }}
                />
              </div>
              <button
                type="submit"
                className="save-button"
                onClick={closePopup}
                style={{marginTop: '10px', padding: '8px 20px 8px 20px'}}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddPatients;