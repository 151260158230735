import Axios from 'axios';
import { toast } from 'react-toastify';
const API_URL = process.env.REACT_APP_API_URL;

export const handleOrderSingleKit = async (e, orderKitInfo, setOrderKitInfo, setShowTermsModal, setOrderPopup, setLoading, hasPaymentDetails, hasLabDetails) => {
    e.preventDefault();
    if(!hasPaymentDetails || !hasLabDetails){
        toast.error('Please add your card details and lab details before placing orders.', {
            position: 'top-center',
        });
        return;
    }

    // Validate required fields
    if (!orderKitInfo.frequency?.trim() || !orderKitInfo.kitType?.trim()) {
        toast.error('Please enter all the required fields.', {
            position: 'top-center',
        });
        return;
    }

    try {
        setLoading(true);

        // Make API call
        const response = await Axios.post(`${API_URL}/alert/request-address-confirmation-single-patient`, { ...orderKitInfo }, {
                headers: {
                    Authorization: localStorage.getItem("token"),
                },
            }
        );

        // Handle successful response
        if (response.status === 200 && response.data.success) {
            toast.success(response.data.message, {
                position: 'top-center',
            });
            setOrderKitInfo({ frequency: '', kitType: '' });
            setShowTermsModal(false);
            setOrderPopup(false);
        } else {
            // Display message from API response or default error message
            toast.error(response.data.message || 'Failed to process the request.', {
                position: 'top-center',
            });
        }
    } catch (error) {
        // Handle errors
        if (error.response) {
            const { status, data } = error.response;
            switch (status) {
                case 400:
                    toast.error(data.message || 'Order has already been placed for this patient.', {
                        position: 'top-center',
                    });
                    break;
                case 403:
                    toast.error(data.message || 'Patient is not authorized.', {
                        position: 'top-center',
                    });
                    break;
                case 500:
                    toast.error(data.message || 'Internal server error. Please try again later.', {
                        position: 'top-center',
                    });
                    break;
                default:
                    toast.error('Unexpected error occurred. Please try again later.', {
                        position: 'top-center',
                    });
            }
        } else {
            toast.error('Network error. Please check your connection and try again.', {
                position: 'top-center',
            });
        }
    } finally {
        setLoading(false);
    }
};

// Function to place orders for authenticated patients
export const handleOrderKits = async (patients, setLoading) => {
    try {
        setLoading(true);
        const response = await Axios.post(`${API_URL}/alert/request-address-confirmation`, { patients }, {
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        });

        if (response.status === 200 && response.data.success) {
            toast.success('Address verification email sent to patients. Kits will ship after verification', {
                position: 'top-center',
            });
        } else {
            console.error('Failed to order kits:', response.data.message);
        }
    } catch (error) {
        toast.error('An error occurred while ordering kits, please try again later!', {
            position: 'top-center',
        });
    } finally {
        setLoading(false);
    }
};