import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GrCircleInformation } from "react-icons/gr";
import { fetchClinics } from '../../ApiHandler/ClinicFunctions.js';
import SearchLocationInput from '../Utils/SearchLocationInput.js';
import { fetchPatientProfile, updatePatientProfile } from '../../ApiHandler/PatientFunctions';
import { fetchKits } from '../../ApiHandler/ShopifyFunctions';

const PatientProfile = ({ handleClick }) => {
    const [formData, setFormData] = useState({
        name: '',
        // email: '',
        phone: '',
        age: '',
        street: '',
        city: '',
        postalCode: '',
        state: '',
        country: '',
        addressLine2: '',
        shippingType: '',
        frequency: '',
        kitType: '',
        clinicId: '',
        clinicName: ''
    });
    const [clinics, setClinics] = useState([]);  // State to store fetched clinics
    const [fetchedClinicId, setFetchedClinicId] = useState('');
    const [isProfileComplete, setIsProfileComplete] = useState(false);
    const [kits, setKits] = useState([]);

    useEffect(() => {
        fetchKits(setKits);
    }, []);

    useEffect(() => {
        fetchPatientProfile(setFormData, setFetchedClinicId);
        fetchClinics(setClinics);
    }, []);

    useEffect(() => {
        const isComplete = Object.entries(formData).every(([key, value]) => {
            // Skip the 'address_line_2' field
            if (key === 'addressLine2') {
                return true;
            }
            return value && value !== '';
        });
        setIsProfileComplete(isComplete);
    }, [formData]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSelectedLocation = (formattedAddress, city, state, country, postalCode) => {
        setFormData((prevData) => ({
            ...prevData,
            street: formattedAddress,
            city: city || prevData.city,
            state: state || prevData.state,
            country: country || prevData.country,
            postalCode: postalCode || prevData.postalCode
        }));
    };

    return (
        <>
            <ToastContainer />
            <div className="patients-container">
                <div className="form-container">
                    <h2>Profile</h2>
                    {!isProfileComplete && (
                        <p style={{ color: '#FFA500' }}>
                            Please complete your profile to be authenticated by clinics and receive your test kits.
                        </p>
                    )}
                    <form className="add-patient-form">
                        <div className='in-row-input'>
                            <div className="form-group">
                                <label>Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    placeholder="Enter Your Name"
                                    required
                                />
                            </div>
                            {/* <div className="form-group">
                            <label>Email</label>
                            <input
                                type="text"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Enter Your Email"
                                required
                            />
                        </div> */}
                            <div className="form-group">
                                <label>Phone Number</label>
                                <input
                                    type="text"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    placeholder="Enter Your Phone Number With Country Code"
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Age</label>
                            <input
                                type="number"
                                name="age"
                                value={formData.age}
                                onChange={handleChange}
                                placeholder="Enter Your Age"
                                min="0"
                                max="120"
                                required
                            />
                        </div>
                        {(fetchedClinicId === 'C000' || fetchedClinicId === null) ? (
                            <div className="form-group">
                                <label>Select Clinic</label>
                                <select
                                    name="clinicId"
                                    value={formData.clinicId}
                                    onChange={handleChange}
                                >
                                    <option value="">Select a Clinic</option>
                                    {clinics.map((clinic) => (
                                        <option key={clinic.clinic_id} value={clinic.clinic_id}>
                                            {clinic.clinic_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        ) : (
                            <div className="form-group">
                                <label>Clinic</label>
                                <input
                                    type="text"
                                    value={formData.clinicName}
                                    readOnly
                                />
                            </div>
                        )}
                        <div className='in-row-input'>
                            <div className="form-group">
                                <label>Street</label>
                                <SearchLocationInput
                                    setSelectedLocation={handleSelectedLocation}
                                    initialQuery={formData.street}
                                />
                            </div>
                            <div className="form-group">
                                <label>City</label>
                                <input
                                    type="text"
                                    name="city"
                                    value={formData.city}
                                    onChange={handleChange}
                                    placeholder="Enter your city"
                                    required
                                />
                            </div>
                        </div>
                        <div className='in-row-input'>
                            <div className="form-group">
                                <label>Postal Code</label>
                                <input
                                    type="text"
                                    name="postalCode"
                                    value={formData.postalCode}
                                    onChange={handleChange}
                                    placeholder="Enter your postal code"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>State</label>
                                <input
                                    type="text"
                                    name="state"
                                    value={formData.state}
                                    onChange={handleChange}
                                    placeholder="Enter your state"
                                    required
                                />
                            </div>
                        </div>
                        <div className='in-row-input'>
                            <div className="form-group">
                                <label>Country</label>
                                <input
                                    type="text"
                                    name="country"
                                    value={formData.country}
                                    onChange={handleChange}
                                    placeholder="Enter your country"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Shipping Type</label>
                                <select
                                    name="shippingType"
                                    value={formData.shippingType}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select</option>
                                    <option value="USPS">USPS</option>
                                    <option value="Overnight">Overnight</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Address Line 2</label>
                            <input
                                type="text"
                                name="addressLine2"
                                placeholder="Enter your address (Optional)"
                                value={formData.addressLine2}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='in-row-input'>
                            <div className="form-group">
                                <label>Frequency</label>
                                <select
                                    name="frequency"
                                    value={formData.frequency}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select</option>
                                    <option value="3 Months">Every 3 Months</option>
                                    <option value="1 Month">Every 1 Month</option>
                                    <option value="Yearly">Every Year</option>
                                    <option value="1 Time">1 Time</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Kit Type</label>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <select
                                        name="kitType"
                                        value={formData.kitType}
                                        onChange={handleChange}
                                        required
                                        style={{ flex: '1' }}
                                    >
                                        <option value="">Select</option>
                                        {kits.map((kit, index) => (
                                            <option key={kit.id} value={kit.id}>
                                                {kit.name}
                                            </option>
                                        ))}
                                    </select>
                                    <button
                                        type="button"
                                        onClick={() => handleClick('kitsInfo')}
                                        style={{
                                            marginLeft: '8px',
                                            marginBottom: '4px',
                                            backgroundColor: 'transparent',
                                            border: 'none',
                                            cursor: 'pointer',
                                            color: '#007bff',
                                            fontSize: '1.5rem',
                                        }}
                                        title="Kits Information"
                                    >
                                        <GrCircleInformation />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <button type="button" className="profile-save-button" onClick={(e) => updatePatientProfile(e, formData)}>Update</button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default PatientProfile;