import Axios from 'axios';
import { toast } from 'react-toastify';
const API_URL = process.env.REACT_APP_API_URL;


// Function to place orders for authenticated patients
export const handleSendInvitation = async (e, formData, setFormData, setShowModal, setLoading) => {
    e.preventDefault();
    if (String(formData.providerType).trim() === '' ||
        String(formData.appointmentDate).trim() === '' ||
        String(formData.timeSlotStart).trim() === '' ||
        String(formData.timeSlotEnd).trim() === '' ||
        String(formData.meetLink).trim() === '' ||
        String(formData.patientId).trim() === '') { // Validate patientId as well
        toast.error('Please enter all the required fields', {
            position: 'top-center',
        });
        return;
    }
    try {
        setLoading(true);
        const response = await Axios.post(`${API_URL}/booking/send-invitation`, { ...formData }, { 
            headers: {
                Authorization: localStorage.getItem("token"),
            }
        });

        if (response.status === 201 && response.data.success) {
            toast.success('Invitation sent successfully!', {
                position: 'top-center',
            });
            setShowModal(false); // Close the modal
            setFormData({ providerType: '', timeSlot: '', meetLink: '', patientId: '' }); // Reset the form
        } else {
            console.error('Failed to send invitation: ', response.data.message);
            toast.error(response.data.message, { position: 'top-center' });
        }
    } catch (error) {
        console.error('Failed to send invitation:', error);
        toast.error('An error occurred while sending invitation, please try again later!', {
            position: 'top-center',
        });
    } finally {
        setLoading(false);
    }
};

// Function to fetch booked appointments
export const fetchBookedAppointments = async (setAppointments) => {
    try {
        const response = await Axios.get(`${API_URL}/booking/fetch-appointments`, {
            headers: {
                Authorization: localStorage.getItem("token"),
            },
        });

        if (response.status === 200 && response.data.success) {
            setAppointments(response.data.appointments);
        } else {
            console.error('Failed to fetch appointments:', response.data.message);
            toast.error('Failed to fetch appointments!', { position: 'top-center' });
        }
    } catch (error) {
        console.error('Error fetching appointments:', error);
        toast.error('An error occurred while fetching appointments. Please try again later!', {
            position: 'top-center',
        });
    }
};
