import React from 'react';
import { IoMdClose } from "react-icons/io";

const TermsModal = ({ show, accept, onClose, loading }) => {
    if (!show) return null;
    const currentDate = new Date().toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className='terms-heading'>
                    {/* <h2>Your credit card will be charged for this kit</h2> */}
                    <h2>Your credit card will be charged for this kit <br></br><br></br>Terms & Conditions</h2>
                    <IoMdClose style={{ cursor: "pointer", fontSize: "30px" }} onClick={onClose} />
                </div>
                <h3>Sign Up Page</h3>
                <p><strong>Effective Date:</strong> {currentDate}</p>
                <p>
                    Welcome to www.getquickcare.com, an e-commerce healthcare technology company that collaborates with clinics and hospitals to offer home-based STI sample collection solutions within the United States. By accessing or using our website, services, or products, you agree to be bound by the following Terms and Conditions. Please read them carefully before proceeding with your purchase.
                </p>
                <h3>1. Scope of Services</h3>
                <p>
                    getquickcare partners with FDA-approved manufacturers to create sample collection kits designed for home use. Our role is limited to facilitating the distribution of these kits to patients and clinics. We do not conduct testing or provide medical advice. Testing is conducted by independent, certified laboratories.
                </p>
                <h3>2. No Medical Liability</h3>
                <p>getquickcare is not responsible for any test results, including false positives or false negatives, as these outcomes are dependent on multiple factors outside of our control, such as:</p>
                <ul>
                    <li>Proper sample collection by the patient or clinician</li>
                    <li>The quality of the specimen in transit</li>
                    <li>The performance of the testing lab</li>
                    <li>The reliability of the test provided by the manufacturer</li>
                </ul>
                <p>
                    By purchasing and using our kits, you acknowledge and agree that getquickcare holds no liability for the accuracy of the test results, and any disputes or concerns should be directed to the testing lab or manufacturer.
                </p>
                <h3>3. Sample Collection & Testing Process</h3>
                <p>
                    You, the customer, are responsible for following the instructions provided with your sample collection kit. Failure to follow these instructions may result in inaccurate or void test results. Should your test be invalid due to improper collection or issues outside our control, getquickcare is not liable.
                </p>
                <h3>4. No Return Policy</h3>
                <p>
                    Due to the nature of the products and the health and safety concerns related to home-based sample collection kits, we do not accept returns under any circumstances. All sales are final.
                </p>
                <h3>5. Replacement of Defective Kits</h3>
                <p>
                    If you receive a defective sample collection kit, please contact us within [Insert Timeframe, e.g., 7 days] of receipt to report the issue. Upon verification of the defect, we will ship a replacement kit to you or your clinic at no additional cost. Defective kits must not be used and should be disposed of immediately.
                </p>
                <h3>6. Limitations of Liability</h3>
                <p>
                    In no event will getquickcare, its affiliates, or partners be liable for any direct, indirect, incidental, or consequential damages arising from the use of our kits or services. This includes, but is not limited to, any damages related to:
                </p>
                <ul>
                    <li>Loss or misuse of personal data</li>
                    <li>Delays in testing due to issues in transit</li>
                    <li>The accuracy or reliability of test results</li>
                    <li>Inaccurate diagnoses or treatments resulting from test outcomes</li>
                </ul>
                <p>
                    Our total liability, in any case, shall not exceed the cost of the defective kit replacement.
                </p>
                <h3>7. Compliance with Laws</h3>
                <p>
                    By using our products, you agree to comply with all applicable federal, state, and local laws and regulations regarding STI testing and healthcare services.
                </p>
                <h3>8. Governing Law</h3>
                <p>
                    These Terms and Conditions are governed by and construed in accordance with the laws of the State of [Insert State], without regard to its conflict of law principles.
                </p>
                <h3>9. Modifications to Terms</h3>
                <p>
                    We reserve the right to modify these Terms and Conditions at any time without prior notice. Any changes will be effective immediately upon posting on our website. Continued use of our services and products following any modification indicates your acceptance of the updated Terms.
                </p>
                <h3>10. Contact Information</h3>
                <p>
                    For any inquiries regarding these Terms and Conditions, defective kits, or other concerns, please contact us at:
                </p>
                <address>
                    www.getquickcare.com <br />
                    Product Advisory <br />
                    981 Mission Street, San Francisco, CA, US, 94103 <br />
                    contactus@productadvisory.org
                </address>
                <br />
                <br />
                <h2>Payment Page</h2>
                <p>
                    By completing your purchase, you agree to the following terms and conditions:
                </p>
                <h3>1. Product Use</h3>
                <p>
                    www.getquickcare.com partners with FDA-approved manufacturers to provide home-based STI sample collection kits. These kits are intended for use under the guidance of clinics or hospitals. getquickcare does not conduct testing or provide medical advice.
                </p>
                <h3>2. No Liability for Test Results</h3>
                <p>
                    getquickcare is not responsible for the accuracy of test results, including any false positives or negatives. Test results are influenced by:
                </p>
                <ul>
                    <li>Sample collection procedures</li>
                    <li>Transportation of the sample</li>
                    <li>The testing laboratory</li>
                    <li>The manufacturer of the testing kit</li>
                </ul>
                <p>
                    3. Any concerns regarding test results should be directed to the relevant testing lab or manufacturer.
                </p>
                <h3>4. No Return Policy</h3>
                <p>
                    Due to the personal and health-sensitive nature of the products, all sales are final. We do not accept returns of any sample collection kits.
                </p>
                <h3>5. Replacement of Defective Kits</h3>
                <p>
                    If you receive a defective kit, contact us within [Insert Timeframe, e.g., 7 days] of receipt. Upon confirmation, we will provide a replacement kit at no additional cost.
                </p>
                <h3>6. Payment</h3>
                <p>
                    By proceeding with your payment, you agree to the final price as displayed on the checkout page, including any applicable taxes and shipping fees. All transactions are securely processed, and we do not store your payment details.
                </p>
                <h3>7. Limitation of Liability</h3>
                <p>
                    getquickcare is not liable for any indirect, incidental, or consequential damages related to the use of our kits, including delays, loss of data, or inaccuracies in test results. Our maximum liability is limited to the replacement of a defective kit.
                </p>
                <h3>8. Governing Law</h3>
                <p>
                    These terms are governed by the laws of [Insert State], without regard to conflict of law principles.
                </p>
                <p>
                    By completing your payment, you confirm that you have read, understood, and agree to these Terms & Conditions. If you have any questions or concerns, please contact us before making your purchase.
                </p>
                <button className='save-button' disabled={loading} onClick={accept}>{loading ? 'Placing Order...' : 'I agree'}</button>
            </div>
        </div>
    );
};

export default TermsModal;